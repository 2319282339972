
import React, { useRef, useState, useLayoutEffect, useEffect, ReactElement } from "react";
import DeckGL from '@deck.gl/react';
import ReactMapGL, { Popup, HTMLOverlay, SVGOverlay } from 'react-map-gl';
import sizeMe, { withSize } from "react-sizeme";
import { getDevices } from "../../state/actions";
import { useGlobalState } from "../../state";
import { Device, HanData } from "../../service";
import _ from 'lodash';
import { Row, Col, Tag } from "antd";
import AnimatedNumber from "animated-number-react";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import Loadable from '@loadable/component';
const LoadableChart = Loadable(() => import("../../../node_modules/react-apexcharts/src/react-apexcharts"));

// @ts-ignore
import styles from "./overview.module.css";

/*
function Sparkline() {
    return ();

}*/

interface IPowerNumber {
    watt?: number;
}
function PowerNumber(props: IPowerNumber) {

    const [magnitude, setMagnitude] = useState<string>("");
    const [power, setPower] = useState<string>("");

    useEffect(() => {
        const l = props.watt.toString().length;
        if (l >= 7) {
            setPower((props.watt / 1000000).toFixed(2));
            setMagnitude("mW");
        }

        if (l >= 4) {
            setPower((props.watt / 1000).toFixed(2));
            setMagnitude("kW");
        }

        if (l < 4) {
            setPower(props.watt.toString());
            setMagnitude("W");
        }
    }, [props.watt]);

    const formatValue = (value) => value.toFixed(2);

    return (
        <Tag style={{ marginTop: 4, borderStyle: "dashed", backgroundColor: "#ececec" }}><span style={{ fontSize: 12, fontWeight: 600 }}><AnimatedNumber value={power} formatValue={formatValue} /> {magnitude}</span></Tag>
    )
}


interface IDeviceOverlay {
    device?: Device;
    x?: number;
    y?: number;
}
function DeviceOverlay(props: IDeviceOverlay) {

    const [handata] = useGlobalState("handata");
    const [latest, setLatest] = useState<HanData>({ pi: 0 });
    const [sparklineData, setSparklineData] = useState<number[]>([]);

    useEffect(() => {
        var latestIndex = _.findLastIndex(handata, function (data: HanData) { return data.deviceUuid === props.device.deviceUuid });
        if (latestIndex != -1) {
            setLatest(handata[latestIndex]);
        }

        var allData = _.filter(handata, function (data: HanData) { return data.deviceUuid === props.device.deviceUuid }).map((item: HanData) => item.pi);

        if (allData.length > 30) {
            allData = allData.slice(allData.length - 30);
        }

        //console.log(allData);
        setSparklineData(allData);


    }, [handata]);

    const options = {
        chart: {
            type: 'line',
            width: 100,
            height: 35,
            sparkline: {
                enabled: true
            },
        },
        colors: ["#4BB2E7"],
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return ''
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    const series = [{
        data: sparklineData
    }];

    return (
        <div style={{ position: "absolute", width: 400, height: 100, /*backgroundColor: "yellow",*/ left: props.x - (400 / 2), top: props.y - (100 / 2), }}>
            <div style={{ width: 20, height: 20, backgroundColor: "#FFF", borderStyle: "solid", borderWidth: 1, borderColor: "#CACACA", position: "absolute", left: 200 - (20 / 2), top: 50 - (20 / 2), borderRadius: 20 }}>
                <div style={{ width: 6, height: 6, backgroundColor: "#939393", borderStyle: "solid", position: "absolute", left: 6, top: 6, borderRadius: 20 }} />
            </div>
            <div style={{ width: 220, backgroundColor: "#FFF", position: "absolute", left: 210, top: -8, borderRadius: 10, padding: 10 }}>
                <span style={{ fontWeight: 600, color: "", fontSize: 18 }} className={styles.devicePopupText}>{props.device.meter.description}</span><br/>
                <PowerNumber watt={latest.pi} />
                <LoadableChart options={options} series={series} type="line" height={35} style={{ marginTop: 10 }} />
            </div>
        </div >
    )
}

/*
 <Sparklines data={sparklineData} limit={20} width={100} height={20} margin={5}>
                    <SparklinesLine style={{ fill: "none" }} />
                    <SparklinesSpots />
                </Sparklines>*/


interface IDevice {
    device?: Device;
}

function DevicePopup(props: IDevice) {
    return (<div>{props.device.meter.description}</div>);
}

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYmxvZXZlciIsImEiOiJjazB4c2NpMXcwMm5uM21xZXU5M203czlnIn0.-rMOfwDgX6w2BYBOEOMnHw';

function Overview({ size }) {

    const [devices] = useGlobalState("devices");
    const [devicePopups, setDevicePopups] = useState<ReactElement[]>([]);

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        console.log("devices changed")

        const _d = devices.map((device: Device) => {
            /*return (<Popup key={device.deviceId}
                style={{backgroundColor: ""}}
                latitude={device.meter.latitude}
                longitude={device.meter.longitude}
                closeButton={false}
                closeOnClick={false}
                anchor="bottom">
                <DevicePopup device={device} />
            </Popup>);*/

            function redraw({ project }) {
                const [x, y] = project([device.meter.longitude, device.meter.latitude]);

                //return <div style={{position: "absolute", width: 100, height: 100, backgroundColor: "red", left: x-(100/2), top: y-(100/2), }}>{device.meter.latitude} - {device.meter.longitude}</div>;
                return <DeviceOverlay x={x} y={y} device={device} />
            }

            return (<HTMLOverlay key={device.deviceId} width="100vw" height="100vh" redraw={redraw}></HTMLOverlay>);

        });
        setDevicePopups(_d);
    }, [devices]);

    async function getData() {
        const response = await getDevices();
        //console.log(response);
    }

    const [view, setViewport] = useState({
        latitude: 59.7141139,
        longitude: 10.236538,
        zoom: 10
    });

    const layers = [];

    function redraw({ project }) {
        const [cx, cy] = project([10.236538, 59.7141139]);
        return <circle cx={cx} cy={cy} r={4} fill="blue" />;
    }

    return (
        <ReactMapGL
            {...view}
            width="100%" // It always override the view(viewport) width state.
            height="100vh" // It always override the view(viewport) height state.
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            onViewportChange={viewport => setViewport(viewport)}
        >
            {devicePopups}
        </ReactMapGL>
    );
}

export default Overview;


/*

*/